@import '@/_styles/import';
$root: '.DisabledSailorModal';

#{$root} {
  text-align: center;

  #FlyoutParent {
    display: flex;
    align-items: center;

    width: 1030px;
    max-width: unset;
    min-height: 400px;
    max-height: 600px;

    @include media-breakpoint-down(xl) {
      width: 1030px;
    }

    @include media-breakpoint-down(lg) {
      width: 1030px;
    }

    @include media-breakpoint-down(md) {
      width: 760px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 100%;
      max-height: unset;
    }
  }

  .Flyout__dismiss {
    @media (max-width: $container-sm-width) and (orientation: landscape) {
      top: 47px;
    }
  }

  .Flyout__dismiss.CloseBtn {
    position: absolute;
  }

  &__container {
    padding: 50px;
  }

  &__heading {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px;

    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }

  &__subHeading {
    margin: 0 auto;
    margin-bottom: 63px;
    padding: 0 195px;

    font-size: 18px;
    line-height: 26px;

    @include media-breakpoint-down(md) {
      padding: 0 60px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 50px;
      padding: 0 35px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .btn.fullwidth {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .SailorServiceCta {
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }
    }

    .CloseBtnCta {
      @include media-breakpoint-up(md) {
        margin-left: 20px;
      }

      .btn {
        height: 49px;
        padding: 0 5px;
        text-align: center;

        @include media-breakpoint-up(md) {
          padding: 0 20px;
        }
      }
    }
  }
}
